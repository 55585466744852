:-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #3C371A !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: #3C371A !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: #3C371A !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #3C371A !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color: #3C371A !important;
}

::placeholder { /* Most modern browsers support this now. */
   color: #3C371A !important;
}

.formSectionHeader {
    color: rgb(194, 166, 136);
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.form-group input, .form-group select  {

    border-radius: 0;
}

.form-group input:focus, .form-group select:focus  {
    background-color: rgb(194, 166, 136);

}


