.App-wrapper {
    display: flex;
    height: calc(100vh - 130px);
    margin-top: 130px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

  }

  .zoom-buttons-container {
    position: absolute;
    bottom: 50px;
    right: 45%;
  }

  .zoom-in-button {
    background-color: #97c1d6 !important;
    height: 60px !important;
    width: 60px !important;
    border-radius: 50% !important;
    /* font-family: 'FontAwesome';
    content: "\f192";
    top: -5px;
    left: 5px;
    opacity: 1;
    font-size: 22px; */
  }

  .zoom-out-button {
    background-color: #97c1d6 !important;
    height: 60px !important;
    width: 60px !important;
    border-radius: 50% !important;
    /* font-family: 'FontAwesome';
    content: "\f192";
    top: -5px;
    left: 5px;
    opacity: 1;
    font-size: 22px; */
  }

  .zoom-buttons-container-floorplan {
    position: fixed;
    bottom: 30px;
    /* left: 21vw; */
    text-align: center;
    width: 14%;
    right: 40%;
  }

  .App-indicator {
    /* background-color: #97c1d6;
    display: inline-block;
    padding: 10px;
    color: #fff;
    border-radius: 8px 8px 0 0 */
  }


  .App-zoomLabel {
    display: inline-block;
    font-family: 'GalanoGrotesque-Medium';
    margin: 0 5px;
    font-size: 1rem;
  }
  
  .App-button {
    display: inline-block;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 10%;
    outline: none;
    background: none;
    font-family: 'GalanoGrotesque-Medium';
    padding-top: 3px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }

  .App-button-image {
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }

  .plotInfoWindow {
    position: absolute;
    width: 550px;
  }

  .plotInfoWindowBefore {
    height: 20px;
    width: 20px;
    background: #37596b;
    position: absolute;
    top: -10px;
    right: 112.5px;
    transform: rotate(135deg);
  }

  .plotCloseButton {
    color: black;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 23px;
    width: 20px;
    font-weight: 400;
  }

  .mapPlotOverlay {
    height: 70px;
    max-height: 70px;
    width: 250px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.viewPropsButtonSiteplan {
  background: #37596b;
  color: white;
  padding: 20px;
  font-weight: bold;
  font-weight: 600;
  margin-left: 12.5%;
  padding: 15px 15px;
  font-size: 14px;
  text-transform: uppercase;
  width: 70%;
  text-align: center;
  border: none;
  margin-top: 10px;
}

.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  }
  or
  .owl-carousel {
  -ms-touch-action: none;
  touch-action: none;
  }