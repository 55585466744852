.tabButton {
    float: none;
    /* margin: 0; */
    padding: 15px 25px;
    color: #37596b;
    position: absolute;
    background: #edf0f2;
    /* margin-top: -17px; */
    right: -10px;
    top: -49px;
}

.tabButton.active:after {
    content: '';
    background: #edf0f2;
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    left: 0;
}

.lowerButtonNarrowTransparent {
    /* display: none; */
}

.lowerButtonNarrowTransparentNew {
    background: transparent;
    font-weight: bold;
    font-weight: 600;
    margin: 5px 0px;
    padding: 3px 15px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    border: none;
    height: 53px;
}