.actionText {
    cursor: pointer;
  }
  
  .actionText:hover {
    text-decoration: none;
  }
  
  .actionContainer {
    cursor: pointer;
  }
  
.filterPropertyViewport {
    height: 0;
    transition: .35s ease height;
}

.filterPropertyViewport.activeFilter {
    height: 560px;
}
