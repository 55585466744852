  .imageFloorplanTextOverlay { 
    position: relative;
    bottom: 92px;
    color: #37596b;
    font-size: 20px;
    font-weight: bold;
  }

  .imageFloorplanWidth {
    width: 500px !important;
  }