.actionText {
  cursor: pointer;
}

.actionText:hover {
  text-decoration: none;
}

.actionContainer {
  cursor: pointer;
}

.filterViewport {
    height: 0;
    transition: .35s ease height;
}

.filterViewport.activeFilter {
    height: 460px;
}
