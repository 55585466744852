.BrainhubCarouselItem div .galleryImage {
    opacity: 1;
    transition: .35s ease opacity;
  }
  
  .BrainhubCarousel__arrows {
    background-color: transparent !important;
    border: none !important;
  }
  
  .BrainhubCarouselItem.BrainhubCarouselItem--active div .galleryImage  {
    opacity: 1 !important;
  }

  .imageTextOverlay { 
    position: absolute;
    bottom: 100px;
    left: 50px;
    color: white;
    font-size: 43px;
    font-weight: 400;
    padding: 10px;
    border-radius: 10px;
    background: rgba(0,0,0,0.5);
  }