.viewPropsButtonSiteplan {
    background: #37596b;
    color: white;
    padding: 20px;
    font-weight: bold;
    font-weight: 600;
    margin-left: 12.5%;
    padding: 15px 15px;
    font-size: 14px;
    text-transform: uppercase;
    width: 70%;
    text-align: center;
    border: none;
    margin-top: 10px;
  }

  .plotInfoWindow {
    position: absolute;
    width: 500px;
    background: white;
    /* height: 224px; */
    height: 200px;
  }

  .plotInfoWindowBefore {
    height: 20px;
    width: 20px;
    background: white;
    position: absolute;
    top: -10px;
    right: 112.5px;
    transform: rotate(135deg);
  }

  .plotCloseButton {
    color: black;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 23px;
    width: 20px;
    font-weight: 400;
  }

  .mapPlotOverlay {
    height: 70px;
    max-height: 70px;
    width: 223px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}
