.statusOverlay {
    position: absolute;
    top: 20px;
    background: #c3cdd2;
    color: white;
    padding: 5px 20px;
    font-size: 20px;
    left: 20px;
    font-weight: bolder;
}

.heartOverlayTrue {
    position: absolute;
    top: 15px;
    color: rgb(55, 89, 107);
    font-size: 35px;
    right: 20px;
    font-weight: bolder;
    padding-right: 0px;
}

.heartOverlayFalse {
    position: absolute;
    top: 15px;
    color: white;
    font-size: 35px;
    right: 20px;
    font-weight: bolder;
    padding-right: 0px;
}

.shareIcon {
    position: absolute;
    top: 15px;
    color: rgb(55, 89, 107);
    font-size: 35px;
    right: 72px;
    font-weight: bolder;
    padding-right: 0px;
}

.closeShareModalIcon {
    position: absolute;
    top: 15px;
    color: rgb(55, 89, 107);
    font-size: 24px;
    right: 24px;
    font-weight: bolder;
    padding-right: 0px;
}